import {JFieldDTO, makeValidationFunction} from "@jnext/commons";
import {CheckoutCMSConfig} from "../pages/Checkout/models";
import {CMS_CONFIG} from "../pages/Checkout/cms";
import {BasketTypeEnum, CheckoutRequest, DigitalCollectionApi} from "@jnext/ts-axios-formatdigitalcollection";
import {HttpService} from "./HttpService";
import axios from "axios";
import {BasketResponse} from "@jnext/ts-axios-formatdigitalcollection";
import {cartStore} from "../store/cartStore";

type Modify<T, R> = Omit<T, keyof R> & R;

type UpdateAddressMockFormWithI18n = JFieldDTO & Modify<JFieldDTO, {
    cmsConfig?: {
        fallBackTitle: string
    }
}>

const UpdateAddressMockForm: UpdateAddressMockFormWithI18n[] = [
    {
        hurlId: "email",
        type: 'JEMAIL',
        cmsConfig: {
            title: 'update_address_form__email',
            fallBackTitle: 'Email *',
            order: 3,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12,
            }
        },
        mandatory: true,
        username: false,
        validationFunction: makeValidationFunction('EMAIL'),
    },
    {
        hurlId: "name",
        type: 'JTEXT',
        cmsConfig: {
            title: 'update_address_form__name',
            fallBackTitle: 'Nome *',
            order: 1,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12,
            }
        },
        mandatory: true,
        username: false,
    },
    {
        hurlId: "surname",
        type: 'JTEXT',
        cmsConfig: {
            title:  'update_address_form__surname',
            fallBackTitle: 'Cognome *',
            order: 2,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12,
            }
        },
        mandatory: true,
        username: false,
    },
    {
        hurlId: "phone",
        type: 'JPHONE',
        cmsConfig: {
            title: 'update_address_form__mobile',
            fallBackTitle: 'Cellulare *',
            order: 4,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12
            }
        },
        mandatory: true,
        username: false,
        //validationFunction: makeValidationFunction('PHONE'),
    },
    {
        hurlId: "country",
        type: 'JCOUNTRY',
        cmsConfig: {
            title: 'update_address_form__nation',
            fallBackTitle: 'Nazione *',
            order: 8,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12
            }
        },
        mandatory: true,
        username: false,
    },
    {
        hurlId: "province",
        type: 'JTEXT',
        cmsConfig: {
            title: 'update_address_form__province',
            fallBackTitle: 'Provincia *',
            order: 6,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12
            }
        },
        mandatory: true,
        username: false,
        validationFunction: makeValidationFunction('PROVINCE'),
    },
    {
        hurlId: "city",
        type: 'JTEXT',
        cmsConfig: {
            title: 'update_address_form__city',
            fallBackTitle: 'Città *',
            order: 7,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12
            }
        },
        mandatory: true,
        username: false,
    },
    {
        hurlId: "postalCode *",
        type: 'JTEXT',
        cmsConfig: {
            title: 'update_address_form__zipcode',
            fallBackTitle: 'Cap *',
            order: 9,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12
            }
        },
        mandatory: true,
        username: false,
        validationFunction: makeValidationFunction('CAP'),
    },
    {
        hurlId: "street",
        type: 'JTEXT',
        cmsConfig: {
            title: 'update_address_form__street',
            fallBackTitle: 'Indirizzo *',
            order: 5,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 12
            }
        },
        mandatory: true,
        username: false,
    },
    {
        hurlId: "note",
        type: 'JTEXTAREA',
        cmsConfig: {
            title: 'update_address_form__note',
            fallBackTitle: 'Note',
            order: 20,
            componentConfig: {
                options: [],
            },
            colConfig: {
                span: 24,
            }
        },
        mandatory: false,
        username: false,
    },

];

export class CheckoutService {

    static async getCheckoutForm(): Promise<UpdateAddressMockFormWithI18n[]> {
        return UpdateAddressMockForm;
    }

    static async doCheckout(initiativeLogicId: string, basketType: BasketTypeEnum, request: CheckoutRequest): Promise<BasketResponse> {
        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);

        const res = await instance.checkout(initiativeLogicId, basketType, request);
        cartStore?.setBasketItems(res?.data?.items);
        return res?.data;
    }

    static async getCms(): Promise<CheckoutCMSConfig> {
        return CMS_CONFIG;
    }
}