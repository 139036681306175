import React from "react";
import {mzUseNavigate} from "../../utils";
import MicroApp from "../../commons/MicroApp";
import {getNavPathFromType, NavigationType} from "@jnext/commons";
import {HttpService} from "../../service/HttpService";

export const ActivateUser = () => {
    const navigate = mzUseNavigate();
    return (
        <>
            {
                HttpService.env?.coreUser &&
                <MicroApp
                    entry={`${HttpService.env?.coreUser}`}
                    props={{
                        type: 'activate-user',
                        onNavigate(type: NavigationType) {
                           return navigate(getNavPathFromType(type))
                        },
                        keyCloakClientId: HttpService.env?.keycloakClientID as string,
                    }}
                />
            }
        </>
    )
}