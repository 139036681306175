import {CMSConfiguration, CMSStaticSection, JCMSStaticSections, JPage} from "@jnext/commons";
import React, {Fragment, useMemo} from 'react';
import {useCMSStore} from 'store/cmsStore';
import MetaTags from "../../components/MetaTags/MetaTags";


const HowTo = () => {
    const { pages } = useCMSStore();
    const config: CMSConfiguration | undefined = useMemo(() => pages?.howTo, [pages.howTo]);

    if (!config || config?.enabled === false) {
        return <div></div>
    }

    return (
        <Fragment>
            {
                config.page && (
                    <JPage {...config.page} options={config.page.options || { headerAlign: 'center' }}>
                        <MetaTags pageConfiguration={config.page}/>
                        <JCMSStaticSections.HowToTemplate {...config.section as CMSStaticSection.HowTo} />
                    </JPage>
                )
            }
        </Fragment>
    );
};

export default HowTo;
