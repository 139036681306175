import { observer } from 'mobx-react';
import MicroApp from '../../../../commons/MicroApp';
import { HttpService } from "../../../../service/HttpService";
import { useAppStore } from "../../../../store/appStore";
import { useConsumerStore } from 'store/consumerStore';
import {GetConsumerDto} from "@jnext/ts-axios-coreuser";

const ProfileInfo = () => {
    const { handleResponseError } = useAppStore();
    const { updateProfile } = useConsumerStore();


    return (
        <>
            {
                HttpService.env?.coreUser && (
                    <MicroApp
                        entry={`${HttpService.env?.coreUser}`}
                        props={{
                            type: "profile-info",
                            onError: handleResponseError,
                            onSuccess: (consumer: GetConsumerDto) => {
                                updateProfile();
                            },
                        }}
                    />)
            }
        </>
    );
};

export default observer(ProfileInfo);
