import React from "react";
import { Tag } from "antd";
import {TranslateComponent, useTranslationWord} from "../../utils";

export enum StatusTagType {
    'TRANSACTION'= 'TRANSACTION',
    'ORDER'= 'ORDER'
}

interface OrderStatusTagProps {
    type: string,
    orderStatus?: string;
}

export const ComponentStatusTag: React.FC<OrderStatusTagProps> = ({type, orderStatus}) => {
    if(!orderStatus) {
        return <></>;
    }

    const translateWord = useTranslationWord();

    return <Tag style={{
        backgroundColor: translateWord(`${type.toUpperCase()}_STATUS_COLOR_${orderStatus}`)
    }}>
        <TranslateComponent text={`${type.toUpperCase()}_STATUS_${orderStatus}`} />
    </Tag>;
}