import {useMemo} from "react";
import {Col, Row} from "antd";
import moment from "moment";
import {FORMAT_DATE, mzUseNavigate, TranslateComponent, useTranslationWord} from "../../../../../utils";
import {RedemptionResponse} from "@jnext/ts-axios-mz";
import {CMSSection, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import {RedemptionService} from "../../../../../service/RedemptionService";
import {snakeCase} from "snake-case";
import {HistoryRewardType} from "../../../../../type/historyReward";
import {RewardTotalsItemValueString} from "../../../../../commons/ItemValue/RewardTotalsItemValue";
import {downloadApprovalPdf} from "../../HistoryRewardsDetail";

interface ElementHeadProps {
    redemption: RedemptionResponse;
    onRedeem: () => void;
    cms: CMSSection;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
}

const ElementHead: React.FC<ElementHeadProps> = ({redemption, onRedeem, cms, type}) => {
    const {
        friendlyId, issues,
        dateTime,
        initiativeName,
        totals,
        orderItems,
        approvalDetail
    } = redemption;

    const completed = useMemo(() => RedemptionService.completed(redemption?.status), [redemption]);
    const deleted = useMemo(() => RedemptionService.deleted(redemption?.status), [redemption]);
    const needToFinalize = useMemo(() => RedemptionService.needToFinalize(redemption?.status), [redemption]);
    const showRawStatus = !completed && !needToFinalize;
    const showTotals = useMemo(() => RedemptionService.showTotals(redemption.totals), [redemption]);
    const translateWord = useTranslationWord();

    const goToRedemption: string | undefined = redemption?.approvalRequested === 'STANDARD' && cms?.table?.goToRedemptionWithAcceptance?.label 
                                                ? cms?.table?.goToRedemptionWithAcceptance?.label 
                                                : cms?.table?.goToRedemption?.label;

    const navigate = mzUseNavigate();
    const openDetailPage = () => {

        const sectionsTypes = {
            [HistoryRewardType.CONTEST]: NavigationTypes.HISTORY_REWARDS_CONTEST_DETAILS,
            [HistoryRewardType.CATALOG]: NavigationTypes.HISTORY_REWARDS_CATALOG_DETAILS,
            [HistoryRewardType.CATALOG_LOYALTY_COLLECTION]: NavigationTypes.HISTORY_REWARDS_CATALOG_LOYALTY_COLLECTION_DETAILS,
            [HistoryRewardType.CATALOG_DIGITAL_WALLET]: NavigationTypes.HISTORY_REWARDS_CATALOG_DIGITAL_WALLET_DETAILS,
        };

        navigate(getNavPathFromType(sectionsTypes[type], {redemptionId: redemption?.logicId}));
    }

    return (
        <Row className="tableHeader">
            {/*LEFT SIDE*/}
            <Col className={'left-side'} span={12}>
                <Row>
                    <Col className={'col1'}>
                        <b>{cms?.table?.orderRequest?.label}</b>
                        <p>n°{friendlyId}</p>
                    </Col>
                    <Col className={'col2'}>
                        <b>{cms?.table?.carriedOut?.label}</b>
                        <p>{moment(dateTime)?.format(FORMAT_DATE)}</p>
                    </Col>
                    {
                        type !== HistoryRewardType.CATALOG &&
                        <Col className={'col3'}>
                            <b>{cms?.table?.initiative?.label}</b>
                            <p>{translateWord(`INITIATIVE_${snakeCase(initiativeName)?.toUpperCase()}`)}</p>
                        </Col>
                    }
                </Row>
                <Row>
                    {(deleted || completed) && <h3 className={'box-title'}>
                        <b>{cms?.options?.releaseTitle}</b>
                        {
                            approvalDetail?.pdfLink &&
                            <p className={'link'}>
                                <span className={'underline'}
                                      onClick={() => downloadApprovalPdf(redemption)}>{cms?.options?.releaseLabel}</span>
                            </p>
                        }
                    </h3>}
                </Row>

            </Col>

            {/*RIGHT SIDE*/}
            <Col className={'right-side'} span={12}>
                <Row justify={'end'} align={'middle'}>
                    <Col className="col1">
                        {
                            showTotals && <>
                                <b><TranslateComponent text='total'/></b>
                                <div className={'totals-container'}>
                                    <RewardTotalsItemValueString points={redemption?.totals?.requiredPoints}
                                                                 additionalCost={redemption?.totals?.additionalCost}/>
                                </div>
                            </>
                        }

                    </Col>
                    <Col className="col2">

                        {needToFinalize &&
                            <div className={'cta'} onClick={onRedeem}>{goToRedemption}</div>}

                        {completed && (
                            <div className={'cta'} onClick={openDetailPage}>
                                {cms?.table?.showDetail?.label}
                            </div>
                        )}

                        {
                            showRawStatus && (
                                <TranslateComponent text={`REDEMPTION_${redemption?.status}`}/>
                            )
                        }
                    </Col>
                </Row>
            </Col>
        </Row>)
}

export default ElementHead;