import { RightOutlined, StarOutlined } from "@ant-design/icons";
import {
    CMSConfiguration,
    JModal,
    NavigationTypes,
    getNavPathFromType,
    getProductImage,
    CMSSection
} from "@jnext/commons";
import {Item, RedemptionResponse} from "@jnext/ts-axios-mz";
import { DeliveryDto } from "@jnext/ts-axios-mz/models/delivery-dto";
import { Order } from "@jnext/ts-axios-mz/models/order";
import { DocumentTypeEnum } from "@jnext/ts-axios-reward";
import { Breadcrumb, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppStore } from "store/appStore";
import { useLoadingStore } from "store/loadingStore";
import { RewardItemValue } from "../../../../commons/ItemValue";
import { RewardTotalsItemValueString } from "../../../../commons/ItemValue/RewardTotalsItemValue";
import { HttpService } from "../../../../service/HttpService";
import { RedemptionService } from "../../../../service/RedemptionService";
import { ResourceService } from "../../../../service/ResourseService";
import { downloadPrivateImg, useCMSStore } from "../../../../store/cmsStore";
import { HistoryRewardType } from "../../../../type/historyReward";
import { FORMAT_DATE, TranslateComponent, generatePathUrl, mzUseNavigate, useTranslationWord } from "../../../../utils";
import { TrackInfo } from "./trackInfo";
import {FooterCardV2} from "../../../../commons/JCardV2/FooterCardV2";

interface ProductGroupV2Props {
    redemption: RedemptionResponse;
    options: any;
    headCms?: CMSSection;
    bodyCms?: CMSSection;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
    setOrderTracking: (order: Order) => void;
}

export const ProductGroupV1: React.FC<ProductGroupV2Props> = ({ redemption,
                                                                  options,
                                                                  headCms,
                                                                  bodyCms,
                                                                  type,
                                                                  setOrderTracking}) => {

    if(options && options.showHistoryCardV2){
        return <></>
    }

    const translateWord = useTranslationWord();

    const Tracking = ({ order, key }: { order: Order, key: number }) => {
        const deliveries = order?.deliveries?.filter(
            (del: DeliveryDto) => (del?.status === 'SENT' || del?.status === 'DELIVERED') && del?.trackingLink && del?.trackingLink != '');
        if (!deliveries || deliveries?.length === 0) return <></>;
        return (
            <Col className={'tracking'} key={key}>
                <div className={'tracking-key'}>{headCms?.table?.trackingLinkLabel?.label}</div>
                <div
                    className={'tracking-value'}>
                    {deliveries?.map((delivery: DeliveryDto) => {
                        return (
                            <a href={delivery?.trackingLink}
                               target={'_blank'}
                               title={delivery?.trackingLink}
                               aria-label={`${translateWord('GO_TO')} ${delivery?.trackingLink}`}
                               className={'tracking-link'}>
                                {headCms?.table?.trackingLinkCtaLabel?.label}
                            </a>
                        )
                    })}
                </div>
            </Col>
        )
    }

    return (
        <ul className={'orders-list'}>
            {
                redemption?.orders?.map((order, i) => (
                    <li key={i}>
                        <div
                            className={'order-number'}>
                            {bodyCms?.table?.orderN?.label} {order.friendlyId}
                        </div>

                        <Tracking order={order} key={i}/>

                        <div className={'products-groups'}>
                            <div className={'product-group'}>
                                <div className={'left-side'}>
                                    {/*PRODUCTS IN ORDER*/}
                                    {
                                        order.products?.map(product => {
                                            const thumbnailImage = getProductImage(product?.product);

                                            return (
                                                <div className={'product-row'}
                                                     key={product?.product?.logicId}>
                                                    <div className={'product-main-data'}>


                                                        {
                                                            thumbnailImage &&
                                                            <img className={'product-thumbnail'}
                                                                 src={generatePathUrl(thumbnailImage)} aria-hidden={true}/>
                                                        }

                                                        {
                                                            !thumbnailImage &&
                                                            <figure/>
                                                        }

                                                        <div className={'product-info'}>
                                                            <div className={'product-brand'}>{product?.product?.brands?.[0]?.title}</div>
                                                            <div className={'product-name'}>
                                                                <div dangerouslySetInnerHTML={{__html: product?.product?.title ?? ''}}/>

                                                                {
                                                                    product?.vouchers && <div>
                                                                        {
                                                                            product?.vouchers?.map((el, i) => (
                                                                                <a key={i} href={el.content}
                                                                                   target={'_blank'}
                                                                                   title={el?.content}
                                                                                   aria-label={`${translateWord('GO_TO')} ${el?.content}`}
                                                                                   className={'voucher-link'}>{bodyCms?.table?.voucherLink?.label}</a>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                }

                                                            </div>

                                                            {
                                                            (product?.value?.wallets || product?.value?.additionalCost) && (
                                                                    <div className={'stars-container mobile'}>
                                                                        <StarOutlined/>
                                                                        <label>-</label>
                                                                    </div>
                                                                )
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className={'product-other-data'}>
                                                        <div className={'quantity-container'}>
                                                            <label>{bodyCms?.table?.quantity?.label}</label>
                                                            <div
                                                                className={'quantity'}>{product?.quantity}</div>
                                                        </div>
                                                        {

                                                            type !== HistoryRewardType.CONTEST &&
                                                            (!!product?.amount || !!product?.cashAmount) &&
                                                            (
                                                                <div className={'stars-container'}>
                                                                    <RewardItemValue
                                                                        amount={product?.amount}
                                                                        cashAmount={product?.cashAmount}
                                                                        walletId={product?.referredWalletConfigurationLogicId}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                <div
                                    className={`right-side`}
                                    style={{
                                        backgroundColor: translateWord(`ORDER_STATUS_COLOR_${order?.orderStatus}`)
                                    }}
                                    onClick={() => setOrderTracking(order)}
                                >
                                    <div className={`order-status-container`}>
                                        <figure>
                                            <img className={''}
                                                 src={generatePathUrl(translateWord(`ORDER_STATUS_ICON_${order?.orderStatus}`))} aria-hidden={true}/>
                                        </figure>
                                        <div className={'order-status'}>
                                            <TranslateComponent
                                                text={`ORDER_STATUS_${order?.orderStatus}`}/>
                                        </div>
                                        {/* <div className={'order-status-subtitle'}>ID: 203454Y4371</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}