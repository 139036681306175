//template1
import React from "react";

export default {
  "--primary": "#052850",
  "--primary-light": "#056EB5",
  "--primary-lighter": "#dae4f7",
  "--primary-dark": "#081b36",
  "--primary-darker": "#030c1a",
  "--secondary": "#d71e48",
  "--secondary-light": "#f1637e",
  "--secondary-dark": "#6e0f24",
  "--tertiary": '#4f4e4e',
  "--tertiary-light": "#e7e2e7",
  "--tertiary-dark": "#3a3939",
  "--textPrimary": '#052850',
  "--textSecondary": '#333333',
  "--textInvert": '#FFFFFF',
  "--success": '#2e8605',
  "--banner-text-color": '#FFFFFF',
  "--header-background": 'var(--background)',
  "--header-shadow": '0 2px 4px 0 rgba(51,51,51,0.1)',
  "--footer-background": 'var(--background)',
  "--background-card": 'var(--white)',
  "--background-contest": 'var(--white)',
  "--background-event": 'var(--white)',
  "--border-radius-standard": 'var(--white)',
  //"--border-radius-large": 'var(--white)',
  "--border-radius-form": 'var(--white)',
  "--font-size": '',
  "--hero-banner-avatar-size": '5rem',
  "--hero-banner-user-text-font-size": '',
  "--hero-banner-wallet-icon-size": '',
  "--hero-banner-wallet-points-font-size": '',
  "--hero-banner-wallet-name-font-size": '',
  "--hero-banner-cta-font-size": '',
  "--font-family": 'Source Sans Pro',
  "--error": '#ff4d4f',
  "--warning": '#ecc819',
  "--gradientTemplate": "linear-gradient(var(--secondary) 0%, var(--primary) 100%)",
  "--colorBtmNavBar": "var(--primary-dark)",
  "--border-color": "rgba(0,0,0,0.25)",

  // Card expiration header colors
  "--expiringHeaderDisabledColor": "white",
  "--expiringHeaderDisabledBackground": "var(--mediumGray)",
  "--expiringHeaderOpenColor": "white",
  "--expiringHeaderOpenBackground": "var(--secondary)",
  "--expiringHeaderWillOpenColor": "white",
  "--expiringHeaderWillOpenBackground": "var(--secondary)",
  "--expiringHeaderCompletedColor": "white",
  "--expiringHeaderCompletedBackground": "var(--mediumGray)",


  "--border-radius-small": "8px",
  "--border-radius-medium": "12px",
  "--border-radius-large": "16px",

  "--profile-title-color": "var(--textPrimary)",
  "--profile-menu-bar-background": "var(--primary)",
  "--profile-menu-active-background": "var(--primary-light)",
  "--profile-menu-item-color": "var(--primary)",

  "--history-reward-title-color": "var(--textPrimary)",
  "--history-reward-table-head-background": "var(--primary-lighter)",
  "--history-reward-table-head-color": "var(--textPrimary)",
  "--history-reward-table-head-ctaColor": "var(--primary-light)",
  "--history-reward-table-body-color": "var(--textPrimary)",
  "--history-reward-table-border-radius": "var(--border-radius-small)",

  "--history-reward-detail-breadcrumbs-color-primary": "var(--textPrimary)",
  "--history-reward-detail-breadcrumbs-color-secondary": "var(----textSecondary)",
  "--history-reward-detail-head-color": "var(--textPrimary)",

  "--history-reward-detail-table-title-color": "var(--primary)",
  "--history-reward-detail-table-color": "var(--textPrimary)",
  "--history-reward-detail-table-brand-color": "var(--primary)",
  "--history-reward-detail-table-order-status-subtitle-color": "var(--tertiary)",

  "--history-beats-title-color": "var(--textPrimary)",
  "--history-beats-table-head-color": "var(--textPrimary)",
  "--history-beats-table-cta": "var(--primary-light)",

  "--history-beats-details-title-color": "var(--textPrimary)",
  "--history-beats-details-table-cta-color": "var(--primary-light)",

  "--product-details-images-border": "rgba(255, 255, 255, 0.5)",
  "--product-details-images-border-active": "var(--primary)",

  "--product-details-images-background": "var(--white)",

  "--catalog-card-header-height": "200px",

  //status history rewards
  "--background-legend" : "#F0F0F0",
  "--background-img-legend" : "#E70000",
  "--status-background-stock" : "#EBF3F9",
  "--status-background-waiting" : "#EBF3F9",
  "--status-color-waiting" : "#E70000",
  "--status-background-sent" : "#FFFAEE",
  "--status-color-sent" : "#333333",
  "--status-background-expired" : "#FFFAEE",
  "--status-color-expired" : "#333333",
  "--status-background-delivered" : "#EEF9EB",
  "--status-color-delivered" : "#333333",
  "--status-background-available" : "#EEF9EB",
  "--status-color-available" : "#333333",
  "--status-background-blocked" : "#FDEBEB",
  "--status-color-blocked" : "#333333",
  "--status-background-annulled" : "#FDEBEB",
  "--status-color-annulled" : "#333333",

  //mgm
  "--textPrimary-mgm": "#052850",
  "--textSecondary-mgm": "#333333",
  "--background-mgm": 'var(--white)',
  "--gradientTemplate-mgm": "linear-gradient(#d71e48 0%, #052850 100%)",
  "--backgroundSection-mgm": '#EAF3F9',



} as React.CSSProperties;
