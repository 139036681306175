import React, {useEffect, useState} from "react";
import MicroApp from "../../commons/MicroApp";
import {HttpService} from "../../service/HttpService";
import {useAppStore} from "../../store/appStore";
import {mzUseNavigate} from "../../utils";
import {useLocation, useParams} from "react-router-dom";
import {useConsumerStore} from "../../store/consumerStore";
import {observer} from "mobx-react";
import {getNavPathFromType, NavigationType} from "@jnext/commons";
import {isDevEnvironment} from "../../components/MetaTags/MetaTagsUtils";
import axios from "axios";

interface CustomProps {
    type?: string;
    customFrontendUrl?: string;
}

const localEndpoint = '//localhost:5002';
/**
 * change this to point to local custom fe
 */
const useLocalEndpoint = false;

export const Custom = observer(({ type, customFrontendUrl }: CustomProps) => {

    const navigate = mzUseNavigate();
    const [avatar, setAvatar]= useState<string>();
    const params = useParams();
    const location = useLocation();
    const { handleResponseError } = useAppStore();
    const endpoint = isDevEnvironment ? (useLocalEndpoint && !!localEndpoint ? localEndpoint : customFrontendUrl) : customFrontendUrl;
    const { consumerInfo, avatarURL } = useConsumerStore();

    useEffect(() => {
        if(avatarURL){
            setAvatar(avatarURL)
        }
    }, [avatarURL]);

    return (<>
                <MicroApp
                    entry={`${endpoint}`}
                    key={params.id ?? type?.toLowerCase()}
                    props={{
                        type: type?.toLowerCase() ?? params.id,
                        onSuccess: async () => {
                        },
                        onError: handleResponseError,
                        onNavigate(type: NavigationType) {
                            return navigate(getNavPathFromType(type))
                        },
                        JNextNavigate: navigate,
                        useParams: params,
                        useLocation: location,
                        keyCloakClientId: HttpService.env?.keycloakClientID as string,
                        userProfile: consumerInfo,
                        avatarURL: avatar,
                        customHttpService: HttpService,
                        customAxios: axios
                    }}
                />
        </>
    )
});