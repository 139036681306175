import React, {useEffect, useState} from 'react';
import {CMSSection} from "@jnext/commons";
import {observer} from "mobx-react";
import '../../../style/T1/HeroCardMobile/index.scss';
import {UserSection} from "../sections/userSection";
import {WalletSection} from "../sections/walletSection";
import {CtaProfileSection} from "../sections/ctaProfileSection";
import {HeroSectionType} from "../index";

type HeroCardProps = {
    sections?: CMSSection[];
    inNavbar?: boolean
}

const HeroCardMobile = ({sections, inNavbar}: HeroCardProps) => {
    const userSection = sections?.find( section => section?.type === HeroSectionType.HERO_SECTION_USER);
    const walletSection = sections?.find( section => section?.type === HeroSectionType.HERO_SECTION_WALLET);
    const profileSection = sections?.find( section => section?.type === HeroSectionType.HERO_SECTION_PROFILE);

    return (
        <div className={`MobileHeroSectionContainer`}>
            <div className={`MobileUserContainer`}>
                {!!userSection && <UserSection section={userSection}/> }
                {!!profileSection && <CtaProfileSection section={profileSection}/> }
            </div>
            <div className={`MobileWalletContainer`}>
                {!!walletSection && <WalletSection section={walletSection}/> }
            </div>
        </div>
    )
}

export default observer(HeroCardMobile);