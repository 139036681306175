import React from "react";
import { JModal } from "@jnext/commons";
import { FORMAT_DATE, useTranslationWord, generatePathUrl } from "../../../../utils";
import { Order } from "@jnext/ts-axios-mz";
import moment from "moment";

interface TrackInfoProps {
    order: Order;
    closeModal: () => void;
}

export const TrackInfo: React.FC<TrackInfoProps> = ({ order, closeModal }) => {
    const translateWord = useTranslationWord();

    return (
        <JModal
            width={800}
            isModalVisible={true}
            footer={null}
            handleCancel={() => closeModal()}
        >
            <div className={'historyRewards-track-modal'}>
                <div className={'track-modal-title'}>
                    Ordine n°{order?.friendlyId}
                </div>
                <div className={'track-modal-timeline'}>
                    <ul>
                        {order?.ordersTracking?.map((track, index) => (
                            <li key={index} className={`order-status-tracking-status--${track?.status}`} data-status={track?.status}>
                                <div className={'status-icon-container'}>
                                    <figure>
                                        <img src={generatePathUrl(translateWord(`ORDER_STATUS_ICON_${track?.status}`))} alt={""} aria-hidden={true}/>
                                    </figure>
                                </div>
                                <div className={`track-content track-content-status--${track?.status}`}>
                                    {
                                        track?.creationDate &&
                                        <div className={'track-data'}>{moment(track?.creationDate)?.format(FORMAT_DATE)}</div>
                                    }
                                    <div className={'track-info'}>
                                        <div className={'track-info-title'}>{translateWord(`ORDER_STATUS_${track?.status}`)}</div>
                                        <div className={'track-info-description'}>
                                            {translateWord(`ORDER_STATUS_DESCRIPTION_${track?.status}`)}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </JModal>
    )
}