import {CMSSection, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import React, {useRef, useState} from "react";
import {Col, Row} from "antd";
import {Categories} from "./Categories";
import {CatalogList} from "../../components/CatalogList";
import {CMSCatalogOptions} from "./CatalogPage";
import {Filters} from "./Filters";
import {GetProductsFilters} from "../../service/CatalogService";
import {mzUseNavigate} from "../../utils";
import {observer} from "mobx-react";
import {CatalogType} from "../../type/catalogTypes";
import MetaTags from "../../components/MetaTags/MetaTags";
import _ from "lodash";
import MessageBox from "../../components/Messages/MessageBox";
import {useMessages} from "../../components/Messages/messageHooks";

interface CatalogProps {
    section: CMSSection;
    type: CatalogType;
    initiativeLogicId: string;
    isPrivate: boolean;
}

const typeToShow = ["COMPANY_CLOSURE"]
export const Catalog: React.FC<CatalogProps> = observer(({type, section, initiativeLogicId, isPrivate}) => {
    const options = section?.options as any as CMSCatalogOptions;
    const [filters, setFilters] = useState<GetProductsFilters>({});
    const navigate = mzUseNavigate();
    const wrapperRef = useRef(null);

    const openProduct = (productId: string) => {
        const paths = {
            [CatalogType.digitalWallet]: isPrivate ? NavigationTypes.CATALOG_DIGITAL_WALLET_DETAILS : NavigationTypes.PUBLIC_CATALOG_DIGITAL_WALLET_DETAILS,
            [CatalogType.loyaltyCollection]: isPrivate ? NavigationTypes.CATALOG_LOYALTY_COLLECTION_DETAILS : NavigationTypes.PUBLIC_CATALOG_LOYALTY_COLLECTION_DETAILS,
        }
        navigate(getNavPathFromType(paths[type], {initiativeLogicId, productId})).then();
    }

    const {messages} = useMessages();

    return (
        <>
            <MetaTags sectionConfiguration={section}/>
            {
                section?.title &&
                <h1 className={'catalog-title'}>{section?.title}</h1>
            }
            {
                section?.text &&
                <div className={'catalog-description'} dangerouslySetInnerHTML={{__html: section.text}}/>
            }
            {
                messages?.filter((message) => typeToShow?.includes(message?.messageType ?? ''))
                    ?.map((message) => {
                        return (
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <MessageBox
                                    msgTitle={message?.msgTitle ?? ''}
                                    msgSubtitle={message?.msgSubtitle ?? ''}
                                    msgBody={message?.msgBody ?? ''}
                                    msgTitleColor={message?.msgTitleColor ?? ''}
                                    msgSubtitleColor={message?.msgSubtitleColor ?? ''}
                                    msgBodyColor={message?.msgBodyColor ?? ''}
                                    fromDate={message?.fromDate ?? ''}
                                    toDate={message?.toDate ?? ''}
                                    msgLogoUrl={message?.msgLogoUrl ?? ''}
                                    msgDisclaimerColor={message?.msgDisclaimerColor ?? ''}
                                />
                            </div>
                        )
                    })}
            {
                options?.hasStandardFilters &&
                <Filters
                    section={section}
                    initiativeLogicId={initiativeLogicId}
                    isPrivate={isPrivate}
                    onChange={(newFilters) => {

                        const clonedFilters = _.clone(filters);

                        // if price is not present -> remove it
                        if (typeof newFilters?.minAmount == 'undefined' || typeof newFilters?.maxAmount == 'undefined') {
                            delete clonedFilters?.minAmount;
                            delete clonedFilters?.maxAmount;
                        }

                        setFilters({
                            ...clonedFilters,
                            ...newFilters,
                        });
                    }}
                />
            }
            <div className={'catalog-component'} ref={wrapperRef}>
                <div className={'catalog-component-content'}>
                    <Row>
                        {
                            options?.hasCategoryFilters &&
                            <Col span={6} className={'categories-container'}>
                                <Categories
                                    initiativeLogicId={initiativeLogicId}
                                    isPrivate={isPrivate}
                                    onChoose={sectorId => {
                                        // Need to clone to prevent update child data
                                        const newFilters = _.clone(filters);

                                        // remove old sectors from filter
                                        if (!sectorId && newFilters?.sectorLogicIds) {
                                            delete newFilters.sectorLogicIds;
                                        }
                                        // set new sectors for filter
                                        else if (sectorId) {
                                            newFilters.sectorLogicIds = [sectorId];
                                        }

                                        // update object
                                        setFilters({
                                            ...newFilters,
                                        });

                                    }}/>
                            </Col>
                        }
                        <Col className={'list-container'} span={options?.hasCategoryFilters ? 18 : 24}>
                            {
                                wrapperRef?.current &&
                                <CatalogList
                                    colNumbers={options?.hasCategoryFilters ? 3 : 4}
                                    pagination={{
                                        hasPagination: options?.pagination,
                                        type: options?.paginationType,
                                        size: options?.paginationSize,
                                    }}
                                    filters={filters}
                                    onProductClick={(productId: string) => openProduct(productId)}
                                    containerElement={wrapperRef.current}
                                    type={type}
                                    initiativeLogicId={initiativeLogicId}
                                    isPrivate={isPrivate}
                                    section={section}
                                />
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
})